body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.progress{
  max-width: 400px;
  margin: 0 auto;
  
}

.main-content, 
.loginPage {
  min-height: calc(100vh - 154.8px);
  
}

@media screen and (min-width: 768px) {
  .main-content, 
  .loginPage {
    min-height: calc(100vh - 131.8px);
  }
}